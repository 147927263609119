import React from "react";
import Container from "../components/common/Container";
import SEO from "../components/common/Seo";
import ExternalLink from "../components/icons/ExternalLink";
import Layout from "../components/layout";
import developers from "../images/learning-hub/developers.png";
import enterprises from "../images/learning-hub/enterprises.png";
import researchers from "../images/learning-hub/researchers.png";

const Card = ({ title, url, img }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <div className="bg-white p-10 rounded-2xl w-full max-w-[254px] md:max-w-[302px] flex flex-col justify-center items-center">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="card_title flex items-center justify-center"
      >
        <span className="inline-block card_title">{title}</span>{" "}
        <ExternalLink className="ml-2" />
      </a>
      <img
        src={img}
        className="min-w-[222px] min-h-[160px] max-h-[160px] max-w-[160px] mt-6 md:mt-10"
        alt="banner"
      />
    </div>
  </a>
);

const LearningHub = () => {
  return (
    <Layout>
      <Container classes="text-center mb-20">
        <h1 className="text-[40px] leading-[48px] font-semibold text-night-rider md:text-[56px] md:leading-[67px] font-ubuntu uppercase flex items-center justify-center mt-6 md:mt-5 md:mb-4 mb-2">
          <span className=" inline-block">Learning&nbsp;</span>
          <span className="text-[42px] md:text-[56px]  text-gradient-one inline-block">
            Hub
          </span>
        </h1>
        <div className="max-w-[675px] mx-auto mb-6 md:mb-10">
          <p className="text-center font-ubuntu font-normal text-sm sm:text-base sm:leading-[25.6px] leading-[22.4px] text-night-rider">
            Welcome to the{" "}
            <a
              href="http://fetch.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              Fetch.AI
            </a>{" "}
            foundation’s Learning Hub. Here you can access comprehensive
            educational resources that’ll shed light on the cutting-edge
            concepts, development tools and robust infrastructure that’ll allow
            you to create and deploy sophisticated products sitting on the edge
            of distributed systems and artificial intelligence.
          </p>
          <p className="text-center font-ubuntu font-normal text-sm sm:text-base sm:leading-[25.6px] leading-[22.4px] mt-5 md:mt-7 text-night-rider">
            Whether you&apos;re a developer, researcher, or simply someone
            who&apos;s curious about the future of technology, we&apos;ve got
            you covered.
          </p>
        </div>
        <div className="grid gap-10 grid-cols-1 md:grid-cols-3 mt-[65px] md:mt-20 max-w-[1030px] w-full mx-auto items-center justify-items-center">
          <Card
            title="Developers"
            url="https://docs.fetch.ai/"
            img={developers}
          />
          <Card
            title="Enterprises"
            url="https://www.bosch.com/research/blog/economy-of-things/"
            img={enterprises}
          />
          <Card
            title="Researchers"
            url="https://fetch.ai/content-hub/"
            img={researchers}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default LearningHub;

export const Head = () => <SEO title="Learning Hub" />;
